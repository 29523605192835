require("babel-polyfill")
require("formdata-polyfill") // needed for IE/Edge
require('./util.js')(document, window)
var _ = require('lodash')
var moment = require('moment')['default']

const SUPPORTED = !!document.createElement('form').checkValidity

const UTC_OFFSET = '-07:00'
const START = 8 // 8am
const END = 18 // 6pm
const NOW = moment(window.serverTime).utcOffset(UTC_OFFSET, true)

window.m = moment

window.showDebug = false

window.debug = function(...args) {
  if (showDebug) console.log(...args)
}

const userAgent = window.navigator.userAgent
var IS_IOS = false
var IS_EDGE = false

debug(userAgent)

if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
  debug('$$> IS_IOS = TRUE <$$')
  IS_IOS = true
}

if (userAgent.match(/Edge/i)) {
  debug('$$> EDGE = TRUE <$$')
  IS_EDGE = true
}

var currentDate,
    currentTime,
    $form,
    $fieldset,
    $prevDay,
    $today,
    $nextDay,
    $date,
    $time,
    $when,
    $openTimeCount,
    $heading,
    $subheading,
    $main,
    $successMsg,
    $serverErrorMsg,
    $unsupportedMsg

document.on('DOMContentLoaded', onPageLoad)

function onPageLoad() {
  // get DOM nodes
  $form = $1('.appts.form')
  if (!$form) return // don't do anything if this page doesn't have the form

  $fieldset = $1('.appts.form fieldset')
  $date = $1('input[name=date]')
  $prevDay = $1('.date-controls .prev')
  $today = $1('.date-controls .today')
  $nextDay = $1('.date-controls .next')
  $time = $1('select[name=time]')
  $when = $1('output[name=when]')
  $openTimeCount = $1('.open-time-count')
  $heading = $1('.appts.form header .heading')
  $subheading = $1('.appts.form header .subheading')
  $main = $1('.appts.form main')
  $successMsg = $1('.appts.form .success-msg')
  $serverErrorMsg = $1('.appts.form .server-error-msg')
  $unsupportedMsg = $1('.appts.form .unsupported-msg')

  if (!SUPPORTED) return onUnsupported()

  // manually called upon page load
  resizeSelect()
  onDateChanged.call($date)

  let _onDateChanged = _.debounce(onDateChanged, 500, { leading: true })
  let _onTimeChanged = _.debounce(onTimeChanged, 250, { leading: true })

  // register event handlers
  window.on('resize', _.debounce(resizeSelect, 250))
  $form.on('submit', onFormSubmit)
  $prevDay.on('click', goPrevDay)
  $today.on('click', goToday)
  $nextDay.on('click', goNextDay)
  $date.on('input', _onDateChanged)
  $date.on('change', _onDateChanged)
  $date.on('blur', _onDateChanged)
  $time.on('change', _onTimeChanged)
}

function onFormSubmit(e) {
  e.preventDefault()

  $form.classList.add('submitted')

  timeValidate()
  reportErrors()

  if ($form.checkValidity()) {
    console.log('**VALID**')
    submitForm()
  } else {
    console.log('**NOT valid**')
    return false
  }
}

function goPrevDay() {
  if (currentDate && currentDate.isValid()) {
    let newDate = currentDate.clone().subtract(1, 'day').format('YYYY-MM-DD')
    changeElementValue($date, newDate)
  }
}

function goToday() {
  if (NOW.isValid()) {
    let newDate = NOW.clone().startOf('day').format('YYYY-MM-DD')
    changeElementValue($date, newDate)
  }
}

function goNextDay() {
  if (currentDate && currentDate.isValid()) {
    let newDate = currentDate.clone().add(1, 'day').format('YYYY-MM-DD')
    changeElementValue($date, newDate)
  }
}

function onDateChanged() {
  debug('onDateChanged')
  var newDate = moment($date.value, ['YYYY-M-D', 'M-D-YYYY'], true).utcOffset(UTC_OFFSET, true)

  if (+currentDate === +newDate) return // if the date hasn't changed, do nothing

  var dateErr = isDateInvalid(newDate)
  currentDate = newDate
  $when.innerHTML = '...'

  debug('- DISABLED $time')
  $time.disabled = true

  if (dateErr) {
    $date.setCustomValidity(dateErr)
    reportErrors($date)
  } else {
    $date.setCustomValidity('')
    reportErrors($date)

    fetchAppts()
  }

  updateWhen()
}

function onTimeChanged() {
  $when.innerHTML = '...'
  debug('onTimeChanged')

  currentTime = $time.value
  timeValidate()

  updateWhen()
}

function updateWhen() {
  var isToday = (+currentDate == +NOW.clone().startOf('day'))
  var timeText = ($time.selectedIndex !== -1 && $time.options[$time.selectedIndex].value)
                  ? $time.options[$time.selectedIndex].text : '<em>??? - ???</em>'

  let when = isToday ? '<b>Today,</b> ' : ''
  when += currentDate.format('ddd, MMM Do')
  when += ' between ' + timeText

  $when.innerHTML = when
}

function submitForm() {
  debug('--SUBMIT--')

  let formData = new FormData($form)
  let data = {}

  formData.forEach(function(value, key){
    data[key] = value;
  })

  data.path = window.location.pathname
  data.userTime = moment().format('lll Z')
  data.userAgent = window.navigator.userAgent

  debug(data)

  disableForm() // MUST BE AFTER GETTING THE FORM DATA

  let fetchOptions = {
    method: 'POST',
    body: JSON.stringify(data),
    headers: new Headers({
      'Content-Type': 'application/json'
    })
  }

  fetch('/appts/book', fetchOptions)
    .then(res => {
      if (res.ok) {
        onSuccess()
      } else if (res.status >= 400 && res.status < 500) {
        let errors = res.json()
        onUserError(errors)
      } else {
        onServerError()
      }
    })
    .catch(err => onServerError())
}

function fetchAppts() {
  let start = currentDate.clone().utcOffset(UTC_OFFSET, true).hour(START).format()
  let end = currentDate.clone().utcOffset(UTC_OFFSET, true).hour(END).format()

  debug('>> fetch appts')

  disableTimes()

  fetch(`/appts?start=${start}&end=${end}`)
    .then(res => {
      if (res.ok) {
        return res.json()
      } else {
        onServerError()
      }
    })
    .then(json => apptsHandler(json))
    .catch(err => onServerError())
}

function disableForm() {
  $fieldset.disabled = true
  $fieldset.classList.add('loading')
}

function disableTimes() {
  $time.disabled = true
  $time.parentElement.classList.add('loading')
}

function enableTimes() {
  $time.disabled = false
  $time.parentElement.classList.remove('loading')
}

function onSuccess() {
  $heading.innerText = 'Thank You'
  $subheading.innerText = 'Your appointment has been scheduled'

  $main.style.display = 'none'
  $serverErrorMsg.style.display = 'none'
  $unsupportedMsg.style.display = 'none'

  $successMsg.style.display = 'block'
}

function onUserError() {
  onServerError()
}

function onServerError() {
  $heading.innerText = 'Error'
  $subheading.innerText = 'We were unable to process your request'

  $main.style.display = 'none'
  $successMsg.style.display = 'none'
  $unsupportedMsg.style.display = 'none'

  $serverErrorMsg.style.display = 'block'
}

function onUnsupported() {
  $heading.innerText = 'Unsupported Browser'
  $subheading.innerText = 'We can not process the request in this web browser'

  $main.style.display = 'none'
  $successMsg.style.display = 'none'
  $serverErrorMsg.style.display = 'none'

  $unsupportedMsg.style.display = 'block'
}

window.onSuccess = onSuccess
window.onServerError = onServerError
window.onUnsupported = onUnsupported

function apptsHandler(appointments) {
  if (!appointments) return debug(':: apptsHandler received no appointments')

  debug('<< render times')
  let fragment = document.createDocumentFragment()
  let availableCount = appointments.length
  debug(availableCount, 'time slots received ::::')

  $time.innerHTML = ''

  appointments.forEach(appt => {
    let el = createApptOption(appt)

    if (el) fragment.appendChild(el)

    if (!appt.available) availableCount--
  })
  debug(availableCount, 'time slots AVAILABLE ::::')

  $time.appendChild(fragment)

  debug('+ ENABLE $time')
  $time.disabled = false

  let desiredOption = $1(`select[name=time] option[value="${currentTime}"]`)
  if (desiredOption && !desiredOption.disabled) changeElementValue($time, currentTime)

  $openTimeCount.innerText = availableCount

  resizeSelect()
  updateWhen()
  enableTimes()
}

function createApptOption(appt) {
  let el = document.createElement('option')
  let start = moment(appt.start)
  let end = moment(appt.end)
  let val = start.format('H')

  el.text = start.format('ha') + ' - ' + end.format('ha')
  el.value = val

  if (!appt.available) {
    currentTime = (val === currentTime) ? '' : currentTime
    if (IS_IOS) return null // Safari mobile's disabled options are hard to tell apart so don't add them
    el.disabled = true
  }

  return el
}

function isDateInvalid(date) {
  let min = moment($date.getAttribute('min'))
  let max = moment($date.getAttribute('max'))

  return (!date.isValid()) ? 'YYYY-MM-DD or MM-DD-YYYY'
    : (min.isValid() && !date.isSameOrAfter(min)) ? 'Date must not be before ' + min.format('M-D-YYYY')
    : (max.isValid() && !date.isSameOrBefore(max)) ? 'Date must not be after ' + max.format('M-D-YYYY')
    : (date.isoWeekday() > 5) ? 'Date must be Mon-Fri'
    : ''
}

function timeValidate() {
  $time.setCustomValidity(!$time.value ? 'Please select a time.' : '')
}

function resizeSelect() {
  if (!IS_EDGE) {
    $time.size = (window.innerWidth > 510) ? $time.options.length : ''
  } else {
    // setting the size here causes a bug in Edge where the page refreshes and then crashes
    // for some reason, having a reasonably lengthed timeout causes it to work (most of the time)
    setTimeout(function() {
      $time.size = (window.innerWidth > 510) ? $time.options.length : ''
    }, 1000)
  }
}

function reportErrors(el) {
  if (el) {
    if (el.reportValidity) el.reportValidity()
  } else {
    if ($form.reportValidity) $form.reportValidity()
    $form.checkValidity()
  }
}

function changeElementValue(el, value) {
  el.value = value
  el.dispatchEvent(newEvent('change'))
}

function newEvent(name) {
  if (typeof(Event) === 'function') {
    return new Event(name)
  } else {
    var event = document.createEvent('Event')
    event.initEvent(name, true, true)
    return event
  }
}
