// require('./lib/promise-polyfill.js') // already done by babel?
require('./lib/fetch-polyfill.js')

/**
 * This file is mostly Paul Irish's bling.js
 * ...minus the bling
 * https://gist.github.com/paulirish/12fb951a8b893a454b32
 */

module.exports = function(document, window) {
  window.$ = document.querySelectorAll.bind(document)
  window.$1 = document.querySelector.bind(document)

  NodeList.prototype.__proto__ = Array.prototype

  Node.prototype.on = window.on = function (name, fn) {
    this.addEventListener(name, fn)
  }

  NodeList.prototype.on = NodeList.prototype.addEventListener = function (name, fn) {
    this.forEach(function (elem, i) {
      elem.on(name, fn)
    })
  }
}
